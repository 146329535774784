<template>
    <div class="flex flex-1 flex-col card card-f-h">
        <ConfirmationModal @success="actionSuccess" />

        <ModalAddPOI
            :visible="modalAddPOIVisible"
            :clientId="clientId"
            @refresh="actionSuccess"
            @close="modalAddPOIVisible = false"
        />

        <ModalEditPOI
            :visible="modalEditPOIVisible"
            :clientId="clientId"
            :poiId="selectedPoiId"
            @refresh="actionSuccess"
            @close="modalEditPOIVisible = false"
        />

        <header class="flex h-10 items-center justify-between border-b border-solid border-gray-200 pb-4 mb-5">
            <h1>{{ $t('client_details.poi') }}</h1>
            <el-button type="primary" @click="showAddPOIModal">
                {{ $t('common.add') }}
            </el-button>
        </header>

        <MapPOITable :tableData="poiData" @handleEdit="showEditPOIModal" @handleDelete="handleDelete" />

        <InitialLoader v-if="$wait.is('loading.initial_*')" />

        <NoDataInformation :data="noDataInformation" waitKey="loading" />
    </div>
</template>
<script>
import API from './map_poi.api';

export default {
    components: {
        MapPOITable:  () => import(/* webpackChunkName: "Client/ClientMapPOITable" */ './MapPOITable'),
        ModalAddPOI:  () => import(/* webpackChunkName: "Client/ModalAddPOI" */ './MapPOIModalAdd'),
        ModalEditPOI: () => import(/* webpackChunkName: "Client/ModalEditPOI" */ './MapPOIModalEdit'),
    },

    props: {
        clientId: {
            type:    Number,
            default: null,
        },
    },

    data() {
        return {
            noDataInformation:   false,
            poiData:             [],
            selectedPoiId:       null,
            modalAddPOIVisible:  false,
            modalEditPOIVisible: false,
        };
    },

    beforeCreate() {
        this.$wait.start('loading.initial_map_poi');
    },

    created() {
        this.getAll();
    },

    methods: {
        async getAll() {
            this.$wait.start('loading.map_poi');
            try {
                this.poiData = await API.getAll(this.clientId);
                this.noDataInformation = this.poiData.length;
            } finally {
                this.$wait.end('loading.map_poi');
                this.$wait.end('loading.initial_map_poi');
            }
        },

        showAddPOIModal() {
            this.modalAddPOIVisible = true;
        },

        showEditPOIModal(id) {
            this.selectedPoiId = id;
            this.modalEditPOIVisible = true;
        },

        async handleDelete(id) {
            this.$store.commit('showConfirmationModal', {
                actionUrl:  API.destroyURL(this.clientId, id),
                actionType: 'destroy',
            });
        },

        actionSuccess() {
            this.getAll();
            this.$emit('clientDataChanged');
        },
    },
};
</script>
