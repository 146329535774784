export default {
    async getAll(clientId) {
        try {
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/clients/clients/${clientId}/map_poi`);
            return data;
        } catch (error) {
            console.log('🚀 ~ file: map_poi.api.js ~ getAll ~ error', error);
            throw error;
        }
    },

    async create(clientId, formData) {
        try {
            await axios.post(`${process.env.VUE_APP_CORE_API_URL}/clients/clients/${clientId}/map_poi`, {
                title:       formData.title,
                description: formData.description,
                street:      formData.street,
                city:        formData.city,
                post_code:   formData.post_code,
                icon:        formData.icon,
                map_lat:     formData.map_lat,
                map_lng:     formData.map_lng,
                client_uuid: formData.client_uuid,
            });
        } catch (error) {
            console.log('🚀 ~ file: map_poi.api.js ~ create ~ error', error);
            throw error;
        }
    },

    async getDetails(clientId, id) {
        try {
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/clients/clients/${clientId}/map_poi/${id}`);
            return data;
        } catch (error) {
            console.log('🚀 ~ file: map_poi.api.js ~ getDetails ~ error', error);
            throw error;
        }
    },

    async update(clientId, id, formData) {
        try {
            await axios.patch(`${process.env.VUE_APP_CORE_API_URL}/clients/clients/${clientId}/map_poi/${id}`, {
                ...formData,
            });
        } catch (error) {
            console.log('🚀 ~ file: map_poi.api.js ~ update ~ error', error);
        }
    },

    destroyURL(clientId, id) {
        return `${process.env.VUE_APP_CORE_API_URL}/clients/clients/${clientId}/map_poi/${id}`;
    },
};
